<template>
  <div class="container-fluid invite-members-view">
    <div class="row mt-3">
      <div class="col-12">
        <router-link to="/team" class="d-flex align-items-center btn tertiary my-3">
          <img src="/images/icons/back_arrow.svg"
            class="hover-scale d-inline-block mr-3" alt="" />Back to Team & Subscription
        </router-link>
      </div>
    </div>
    <div class="row  mt-6">
      <div class="col-8 offset-2">
        <h1>Invite Team Members</h1>

        <p class="mt-4" v-if="isSamlAuth">
          Your team settings limit users to email addresses at the following domains:
          <strong v-for="(d, i) in allowedDomains" :key="'domain-' + i">
            {{i === allowedDomains.length - 1 ? d + '.' : d + ','}}
          </strong>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-8 offset-2">
        <div v-for="(member, index) in $v.newMembers.$each.$iter"
          :key="index"
          class="row d-flex align-items-end">
          <div
            class="col d-flex flex-row align-items-baseline">
            <button class="mr-4" @click="removeMember(index)" :disabled="index === 0">
              <icon name="remove" height="7" width="7"/>
            </button>
            <div class="d-flex flex-wrap input-group"
              :class="{'invalid': member.email.$error}">
              <b-input
                type="text"
                v-focus
                v-model.trim="member.email.$model"
                lazy-formatter
                :id="'email-' + index"
                :ref="'email-' + index"
                data-tour="invite-email"
                aria-label="Email"
                placeholder="Email"
                class="bordered"/>
              <Error>
                <span v-if="!member.email.required">Email is a required field</span>
                <span v-if="!member.email.email">Not a valid email address</span>
              </Error>
            </div>
          </div>
          <div
            class="col d-flex flex-wrap input-group"
            :class="{'invalid': member.firstName.$error}">
            <b-input
              type="text"
              v-model.trim="member.firstName.$model"
              lazy-formatter
              :state="member.firstName.$error"
              :id="'first-name-' + index"
              data-tour="invite-first-name"
              aria-label="First Name"
              placeholder="First Name"
              class="bordered fs-block"/>
            <Error>
              <span>First Name is a required field</span>
            </Error>
          </div>
          <div
            class="col d-flex input-group"
            :class="{'invalid': member.lastName.$error}">
            <b-input
              type="text"
              v-model.trim="member.lastName.$model"
              lazy-formatter
              :state="member.lastName.$error"
              :id="'last-name-' + index"
              data-tour="invite-last-name"
              aria-label="Last Name"
              placeholder="Last Name"
              class="bordered fs-block"/>
            <Error>
              <span>Last Name is a required field</span>
            </Error>
          </div>
          <div class="col d-flex input-group">
            <select v-model="member.teamRole.$model" class="bordered" data-tour="invite-role">
              <option value="creator">Flight Creator</option>
              <option value="team-administrator">Admin</option>
            </select>
          </div>

          <div class="col-12 ml-5" :class="{'invalid': anyInvalid}">
            <b v-if="!anyInvalid && member.$invalid">* All fields are required</b>
            <Error v-if="doesEmailExist(member.email.$model)">
              <span>There is already a team member with this email address</span>
            </Error>
            <Error v-if="hasEarlierDuplicate(member.email.$model, index)">
              <span>You are already adding a team member with this email address</span>
            </Error>
            <Error v-if="isSamlAuth && isExternalDomain(member.email.$model)">
              <span>You cannot add emails outside your allowed domains</span>
            </Error>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <button
              @click="addMember"
              type="button"
              :disabled="anyInvalid || pending || this.$v.newMembers.$invalid"
              class="my-5 d-flex align-items-center"
            >
              <img src="/images/icons/add.svg" class="hover-scale mr-3" alt="" />
              <span class="tertiary">add a team member</span>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col d-flex justify-content-end mb-5">
            <button
              @click="previewUpdates"
              type="buton"
              class="primary"
              :disabled="anyInvalid || pending || this.$v.newMembers.$invalid"
            >
              {{newMembers.length > 1 ? `invite ${newMembers.length} members` : 'invite 1 member'}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <LeaveGuard />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import LeaveGuard from '@/components/LeaveGuard.vue';
import subscriptionService from '../../services/subscription';
import Error from '../../components/Globals/Error.vue';
import { formatPreviewMessage } from '../../utils/subscriptionPlans';

export default {
  components: { Error, LeaveGuard },
  name: 'TeamMembersInviteView',

  mixins: [validationMixin],

  data() {
    return {
      pending: true,
      newMembers: [{
        email: '',
        firstName: '',
        lastName: '',
        teamRole: 'creator',
        participantLimit: null,
        planId: '',
      }],
    };
  },

  validations: {
    newMembers: {
      $each: {
        email: {
          required,
          email,
        },
        firstName: { required },
        lastName: { required },
        teamRole: { required },
        participantLimit: { required },
      },
    },
  },

  computed: {

    ...mapState({
      activeTeamId: (state) => state.teams.activeTeamId,
    }),

    ...mapGetters([
      'isSamlAuth',
      'doesEmailExist',
      'allowedDomains',
      'isExternalDomain',
      'billingMethod',
      'participantLimitOnActiveTeam',
      'planIdOnActiveTeam',
    ]),

    anyInvalid() {
      return this.newMembers.some((invitee, i) => this.doesEmailExist(invitee.email)
        || this.hasEarlierDuplicate(invitee.email, i)
        || (this.isSamlAuth && this.isExternalDomain(invitee.email)));
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.pending = false;
      this.newMembers = [{
        email: '',
        firstName: '',
        lastName: '',
        teamRole: 'creator',
        participantLimit: this.participantLimitOnActiveTeam,
        planId: this.planIdOnActiveTeam,
      }];
    });
  },

  methods: {

    async getSubscriptionPreview() {
      const selected = {};
      this.newMembers.forEach((m) => {
        if (!selected[m.planId]) {
          selected[m.planId] = 1;
        } else {
          selected[m.planId] += 1;
        }
      });

      if (this.billingMethod === 'stripe') {
        const data = await subscriptionService.previewSubscriptionUpdate(
          this.activeTeamId,
          selected,
        );
        return formatPreviewMessage(data);
      }
      return formatPreviewMessage({ totalCount: 0 });
    },

    async previewUpdates() {
      try {
        this.pending = true;
        const preview = await this.getSubscriptionPreview();
        this.$store.dispatch('showLeaveGuard', {
          message: `${preview}`,
          cancelText: 'Cancel changes',
          okText: 'Accept changes',
          okHandler: async () => {
            await this.inviteUsers();
          },
          cancelHandler: async () => {
            this.pending = false;
          },
        });
      } catch (err) {
        this.resetData();
      }
    },

    async inviteUsers() {
      try {
        await this.$store.dispatch('inviteAllUsers', { invites: this.newMembers });
        const successMessage = this.newMembers.length > 1
          ? `${this.newMembers.length} members invited!` : '1 member invited!';
        this.$store.dispatch('successToast', successMessage);
        this.resetData();
      } catch (err) {
        this.resetData();
      }
    },

    resetData() {
      this.pending = false;
      this.newMembers = [{
        email: '',
        firstName: '',
        lastName: '',
        teamRole: 'creator',
        participantLimit: this.participantLimitOnActiveTeam,
        planId: this.planIdOnActiveTeam,
      }];
      this.$v.$reset();
      this.$nextTick(() => this.$refs['email-0'][0].focus());
    },

    addMember() {
      this.newMembers.push({
        email: '',
        firstName: '',
        lastName: '',
        teamRole: 'creator',
        participantLimit: this.participantLimitOnActiveTeam,
        planId: this.planIdOnActiveTeam,
      });
    },

    removeMember(index) {
      this.newMembers.splice(index, 1);
    },

    hasEarlierDuplicate(val, index) {
      return !!this.newMembers.slice(0, index)
        .find((el) => el.email.toLowerCase() === val.toLowerCase());
    },
  },
};
</script>
