import { format } from 'date-fns';

/**
 * get plan for given tier and interval
 * @param {*} plans
 * @param {string} tier
 * @param {string} interval
 * @returns {plan}
 */
export function getPlan(plans, tier, interval) {
  function byTierAndInterval(plan) {
    return plan.tier === tier && plan.interval === interval;
  }
  return plans.filter(byTierAndInterval)[0];
}

/**
 * get plans for given interval
 * @param {*} plans
 * @param {string} interval
 * @returns {*}
 */
export function getPlans(plans, interval) {
  function byInterval(plan) {
    return plan.interval === interval;
  }
  function sortByLimitDescending(a, b) {
    return b.limit - a.limit;
  }
  return plans.filter(byInterval).sort(sortByLimitDescending);
}

/**
 * get the amount for a plan by tier and interval
 * @param {*} plans
 * @param {string} tier
 * @param {string} interval
 * @returns
 */
export function planAmount(plans, tier, interval) {
  return getPlan(plans, tier, interval).amount;
}

/**
 * is this plan an annual plan?
 * @param {*} plan
 * @returns {boolean}
 */
export function isAnnualPlan(plan) {
  return plan.interval === 'year';
}

/**
 * format a subscription preview message
 * @param {*} data - preview data from api
 * @param {*} data.interval
 * @param {*} data.amountDue
 * @param {*} data.quantity
 * @param {*} data.endDate
 * @returns {string}
 */
export function formatInvoiceMessage(data) {
  const {
    interval, amountDue, quantity, endDate,
  } = data;
  const fmtDueDate = format(endDate, 'MMMM D, YYYY');
  return `You will be charged $${amountDue} on ${fmtDueDate}. This amount includes ${quantity} Flight Creators/${interval} and prorated charges for this current billing period.`;
}

/**
 * format a subscription preview message
 * @param {*} data - preview data from api
 * @param {*} data.interval
 * @param {*} data.totalCount
 * @param {*} data.amountDue
 * @param {*} data.quantity
 * @param {*} data.endDate
 * @returns {string}
 */
export function formatPreviewMessage(data) {
  if (data.totalCount === 0) {
    return 'You will not be charged extra as you will use an existing open Flight Creator seat';
  }
  return formatInvoiceMessage(data);
}
