<template>
  <div :class="{'invalid': error }">
    <div class="subscription-input" v-stripe="{ onChange, onSet }" ref="card"></div>
    <Error v-if="error && error.message">
      <span>{{ error.message }}</span>
    </Error>
  </div>
</template>

<script>
export default {
  name: 'StripeCreditCard',

  data() {
    return {
      error: {},
      isComplete: false,
    };
  },

  computed: {
    isValid() {
      return this.isComplete && !this.error;
    },
  },

  methods: {
    save() {
      this.$emit('save');
    },

    onChange({ error, complete }) {
      this.isComplete = complete;
      this.error = error ? ({ ...error }) : null;
      this.$emit('valid', this.isValid);
    },

    onSet(stripeCard) {
      this.$emit('set', stripeCard);
    },
  },
};
</script>

<style lang="less" scoped>
.subscription-input {
  border: solid 1px @light-navy;
  padding: 1.4rem;
}

a:hover {
  text-decoration: underline;
}
</style>
