<template>
  <b-modal
    id="leave-guard-modal"
    v-model="showModal"
    hide-header
    @ok="clickOk"
    @cancel="clickCancel"
    lazy
    centered
    no-close-on-backdrop
    bodyClass="p-4">

    <div class="close">
      <icon name="remove" color="medium-tan" @click.native="clickCancel" />
    </div>

    <p class="p-4">{{message}}</p>

    <template v-slot:modal-footer>
      <div class="w-100 p-4 d-flex justify-content-between">
        <button @click="clickCancel" class="tertiary w-50">{{ cancelText || 'Cancel'}}</button>
        <button @click="clickOk" class="primary w-50" :class="{ 'bg-orange': orangeButton }">
          {{ okText || 'Continue without saving' }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LeaveGuard',

  data() {
    return {
      showModal: false,
    };
  },

  props: {
    orangeButton: { type: Boolean, default: false },
  },

  computed: {
    ...mapState({
      showLeaveGuard: (state) => state.utils.showLeaveGuard,
      to: (state) => state.utils.leaveGuardTo,
      message: (state) => state.utils.leaveGuardMessage,
      okText: (state) => state.utils.okText,
      cancelText: (state) => state.utils.cancelText,
      okHandler: (state) => state.utils.okHandler,
      cancelHandler: (state) => state.utils.cancelHandler,
    }),
  },

  watch: {
    showLeaveGuard() {
      this.showModal = this.showLeaveGuard;
    },
  },

  methods: {
    clickOk() {
      if (this.okHandler) {
        this.okHandler();
        this.$store.dispatch('clearLeaveGuard');
        return;
      }

      this.$emit('ok-selected');
      this.$router.push(this.to, () => this.$store.dispatch('clearLeaveGuard'));
    },

    clickCancel() {
      if (this.cancelHandler) {
        this.cancelHandler();
        this.$store.dispatch('clearLeaveGuard');
        return;
      }

      this.$store.dispatch('clearLeaveGuard');
      this.$emit('cancel-selected');
    },
  },
};
</script>
