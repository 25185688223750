<template>
  <section>
    <div class="row align-items-end">
      <div class="col-12 col-xl-6 order-last order-xl-first" data-tour="user-filters">
        <SelectionControl
          class="borderless mt-5 mt-xl-0"
          role="button"
          :is-active="value.currentTab === 'team-administrator'"
          @click.native="update('currentTab', 'team-administrator')">
          <span class="green">
            Admin & Flight Creators ({{ totalCount.creators }})
          </span>
        </SelectionControl>
        <SelectionControl
          class="borderless mt-5 mt-xl-0"
          role="button"
          :is-active="value.currentTab === 'contributors'"
          :disabled="!totalCount.contributors"
          @click.native="update('currentTab', 'contributors')">
          <span class="green">
            Contributors ({{ totalCount.contributors }})
          </span>
        </SelectionControl>
      </div>
      <div class="col-6 col-xl-3">
        <div class="input-group input-search">
          <b-input type="search"
          v-model.trim="value.searchString"
          :state="searchState"
          data-tour="search-user"
          lazy-formatter
          id="user-search"
          class="bordered iconed"/>
          <label for="user-search">
            <icon name="search" width="16" height="17" />
            <span class="navy">Search</span>
          </label>
          <b v-if="value.searchString"
            class="green flex-shrink-0 search-count">
            {{ searchCount }}
          </b>
        </div>
      </div>
      <div class="col-6 col-xl-3 d-flex justify-content-end">
        <router-link
          :to="{ name: 'team-members-invite' }"
          :disabled="!isActiveSubscription"
          data-tour="invite-user"
          tag="button"
          class="primary w-100"
        >Invite team members</router-link>
        <Tip
          v-if="!isActiveSubscription"
          class="expired-subscription"
          :show-title="false">
          Reactivate your subscription to invite or update members.
        </Tip>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectionControl from '@/components/SelectionControl.vue';

export default {
  name: 'TeamMembersListHeader',

  components: { SelectionControl },

  props: {
    searchCount: { type: Number },
    totalCount: { type: Object },
    value: {
      currentTab: '',
      searchString: '',
    },
  },

  computed: {
    ...mapGetters(['isActiveSubscription']),

    searchState() {
      return this.value.searchString.length === 0 ? null : true;
    },
  },

  methods: {
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value });
    },
  },
};
</script>

<style lang="less" scoped>
section > .row {
  min-height: 6rem;
}

#user-search {
  min-height: 3.5rem;

  // Hide 'x' icon when input has focus in Safari, Chrome and IE
  &::-webkit-search-cancel-button { -webkit-appearance: none }
  &::-ms-clear, &::-ms-reveal {
    display: none;
    width : 0;
    height: 0;
  }
}

.search-count {
  right: 10px;
  bottom: 10px;
  position: absolute;
  background-color: @pink;
  padding: .5rem;
}

.expired-subscription {
  position: absolute;
  left: 100%;
  min-width: 10rem;
}
</style>
