<template>
  <button
    type="button"
    @click.prevent="click"
    class="tab"
    :class="{ active: isActive, invalid: isInvalid }"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'SelectionControl',
  props: {
    isActive: { type: Boolean, default: false },
    isInvalid: { type: Boolean, default: false },
  },

  methods: {
    click() {
      this.$emit('select');
    },
  },
};
</script>

<style lang="less" scoped>
.tab {
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin: 1em 1.8em 1em 0;
  border-radius: 1px;
  border: solid 1px @light-navy;
  padding: 1.3rem 1.6rem;
  min-width: 110px;
  text-transform: capitalize;

  &.disabled {
    cursor: not-allowed;
  }

  &:hover {
    border-color: @teal;
  }

  &.active {
    border: solid 2px @teal;
    outline: none !important;

    &.invalid {
      border: solid 2px @orange;
    }
  }

  &.borderless {
    min-width: 5rem;
    border: none;
    border-bottom: solid 2px transparent;
    padding: 1.3rem 0;
    margin: 0 1.8em 0 0;

    &.active {
      border: none;
      border-bottom: solid 2px @teal;
    }
  }

  span {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.38;
  }
}

</style>
