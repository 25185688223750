<template>
  <section class="mt-7">
    <div class="row">
      <div class="col-8 offset-2 h-100 d-flex flex-column">
        <h1>Your Plan</h1>

        <template v-if="!isTypeStripe">
          <div class="bg-navy p-5 mt-5 plan">
            <h4 class="mb-4 tan">Plan</h4>
            <h1 class="tan text-capitalize"> {{ plan }}</h1>
          </div>
        </template>

        <template v-else>
          <LoadingBalloonAnimation v-if="!subscriptionInfo.subscription" />
          <div v-else class="d-inline-flex mt-5 w-100 stripe">
            <div class="bg-navy p-5 plan">
              <h4 class="mb-4 tan">Admin Plan</h4>
              <h1 class="tan text-capitalize"> Balloon {{ adminPlan }}</h1>
            </div>
            <div class="d-inline-flex justify-content-between w-100 bg-medium-tan p-4">
              <div class="p-4 mr-4 flight-creators">
                <h4 class="mb-4">Flight Creators & Plans</h4>
                <p>
                  You have <b>{{ flightCreators }}
                  Flight Creator{{ flightCreators > 1 ? 's' : ''}}</b> and
                </p>
                <p>
                  <b>{{ invoicedSeats }} invoiced</b>
                  Flight Creator{{ invoicedSeats === 1 ? ' seat' : 's seats'}}.
                </p>
                <p>{{ remainingSeatsMessage }}</p>
                <div class="seats mt-4">
                  <p v-for="(i, index) in items"
                  :key="index"
                  class="capitalize d-flex justify-content-between">
                    {{ i.tier }} Plan: <b>{{ i.qty }}</b>
                  </p>
                </div>
              </div>
              <div class="p-4 mr-auto w-100" v-if="showCreditCard">
                <StripeCreditCard @set="setStripeCard" @valid="validateCreditCard" />
                <div class="text-right">
                  <button class="tertiary" @click="toggleCreditCard">Cancel</button>
                  <Button
                  :is-loading="isLoading"
                  :disabled="!isCreditCardValid"
                  class="primary bg-orange mt-5"
                  @click="updatePaymentMethod">
                  Add Payment Method
                </Button>
                </div>
              </div>
              <div class="p-4 mr-auto" v-else>
                <h4 class="mb-4">Payment</h4>
                <div class="h-75 d-flex flex-column align-items-start">

                  <template v-if="status === 'trialing'">
                    <p>Your free trial will end on <b>{{ trialEndDate }}</b>.</p>

                    <span class="d-block mt-4" v-if="subscriptionInfo.source">
                      <b>{{ subscriptionInfo.source.TypeData.brand }} ending in
                         {{ subscriptionInfo.source.TypeData.last4 }}</b>
                    </span>

                    <div class="mt-5">
                      <Button :is-loading="isLoading" class="tertiary" @click="cancel">
                        Cancel Subscription
                      </Button>
                      <button class="primary bg-orange mt-5" @click="toggleCreditCard">
                        {{ subscriptionInfo.source
                          ? 'Update Payment Method' : 'Add Payment Method' }}
                      </button>
                    </div>
                  </template>

                  <template v-else-if="status === 'active'">
                    <p>
                      {{ subscriptionMessage }}<br/>

                      <span class="d-block mt-4" v-if="subscriptionInfo.source">
                        <b>{{ subscriptionInfo.source.TypeData.brand }} ending in
                           {{ subscriptionInfo.source.TypeData.last4 }}</b>
                      </span>
                    </p>

                    <div class="mt-5">
                      <Button :is-loading="isLoading" class="tertiary" @click="cancel">
                        Cancel Subscription
                      </Button>
                      <button class="primary bg-orange" @click="toggleCreditCard">
                        Change Payment Method
                      </button>
                    </div>
                  </template>

                  <template v-else-if="status === 'canceled'">
                    <p>
                      Your subscription has been cancelled and
                      {{ isRenewalDateInFuture ?'will end' : 'was ended'}} with <br/>
                      your {{ isRenewalDateInFuture ? 'current' : 'last'}}
                      billing cycle on <b>{{ renewalDate }}</b>.
                    </p>
                    <div class="mt-5">
                      <button class="primary bg-orange" @click="restart">
                        Restart Subscription
                      </button>
                    </div>
                  </template>

                  <template v-else-if="status === 'past_due'">
                    <p>
                      Your subscription could not be renewed and will end with <br/>
                      your current billing cycle on <b>{{ renewalDate }}</b>.
                    </p>
                    <button class="primary bg-orange" @click="toggleCreditCard">
                      Update Payment Method
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <LeaveGuard orange-button />
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { format, isAfter } from 'date-fns';
import { mapGetters } from 'vuex';

import LeaveGuard from '@/components/LeaveGuard.vue';
import StripeCreditCard from '@/components/Forms/StripeCreditCard.vue';
import subscriptionService from '@/services/subscription';
import { formatInvoiceMessage } from '../../utils/subscriptionPlans';

export default {
  name: 'TeamSubscription',

  components: { LeaveGuard, StripeCreditCard },

  props: {
    teamId: { type: String, required: true },
    type: { type: String, required: true },
  },

  data() {
    return {
      items: [],
      showCreditCard: false,
      isLoading: false,
      isCreditCardValid: false,
      stripeCard: null,
    };
  },

  computed: {
    ...mapGetters({
      subscriptionInfo: 'activeTeamStripeInfo',
      users: 'allUsers',
      loggedInUser: 'loggedInUser',
      team: 'activeTeam',
      isSubscriptionCanceled: 'isSubscriptionCanceled',
    }),

    isTypeStripe() { return this.type === 'stripe'; },

    isAnnual() {
      return !!this.items.find((item) => item.interval === 'year');
    },

    plan() {
      return this.type === 'invoiced' ? 'Custom Invoice' : 'Free Plan';
    },

    status() {
      if (this.isSubscriptionCanceled) {
        return 'canceled';
      }
      return this.subscriptionInfo.subscription.status;
    },

    adminPlan() {
      const planLimit = this.loggedInUser.teams
        .find((t) => t.teamId === this.teamId).plan.flightParticipantLimit.toString();
      return this.items.find((plan) => plan.limit === planLimit)?.tier;
    },

    renewalDate() {
      return format(this.subscriptionInfo.subscription.current_period_end * 1000, 'MMMM D, YYYY');
    },

    isRenewalDateInFuture() {
      return isAfter(this.renewalDate, new Date());
    },

    trialEndDate() {
      return format(this.subscriptionInfo.subscription.trial_end * 1000, 'MMMM D, YYYY');
    },

    planAmount() {
      return this.items.reduce((sum, plan) => sum + (plan.price * plan.qty), 0);
    },

    subscriptionMessage() {
      const invoiceData = {
        interval: this.isAnnual ? 'annual' : 'monthly',
        amountDue: this.invoiceAmount,
        quantity: this.invoicedSeats,
        endDate: this.renewalDate,
      };
      return formatInvoiceMessage(invoiceData);
    },

    invoiceAmount() {
      const remaining = this.subscriptionInfo?.nextInvoice?.amount_remaining ?? 0;
      if (remaining === 0) {
        return remaining;
      }
      return remaining / 100;
    },

    invoicedSeats() {
      return this.items.reduce((sum, { qty }) => sum + qty, 0);
    },

    flightCreators() {
      return this.users
        .filter((u) => u.teamStatus !== 'deactivated')
        .filter((u) => u.teamRole === 'creator' || u.teamRole === 'team-administrator')
        .length;
    },

    remainingSeatsMessage() {
      const count = this.invoicedSeats - this.flightCreators;
      const status = count >= 0 ? 'open' : 'unpaid';
      const remaining = Math.abs(count);
      const seats = remaining === 1 ? 'seat' : 'seats';
      return `Leaving ${remaining} ${seats} ${status}.`;
    },
  },

  watch: {
    subscriptionInfo(newValue) {
      if (newValue) {
        this.items = this.parseItems();
      }
    },
  },

  created() {
    if (this.subscriptionInfo.subscription) {
      this.items = this.parseItems();
    }
  },

  methods: {
    toggleCreditCard() {
      this.showCreditCard = !this.showCreditCard;
    },

    validateCreditCard(isValid) {
      this.isCreditCardValid = isValid;
    },

    async updatePaymentMethod() {
      try {
        this.isLoading = true;
        await subscriptionService.updateTeamPaymentMethod(this.team.id, this.stripeCard);
        this.$store.dispatch('successToast', 'Your payment method has been saved');
        await this.$store.dispatch('setTeamSubscription', this.team.id);
      } catch (err) {
        this.$store.dispatch('errorToast', err.message);
      } finally {
        this.isLoading = false;
        this.toggleCreditCard();
      }
    },

    setStripeCard(stripeCard) {
      this.stripeCard = stripeCard;
    },

    parseItems() {
      const items = this.subscriptionInfo?.subscription?.items?.data ?? [];
      let parsedItems = [];
      items.forEach((i) => {
        parsedItems = [
          ...parsedItems,
          {
            tier: i.plan?.tier,
            limit: i.plan?.limit,
            qty: i.quantity,
            interval: i.plan.interval,
            price: i.plan.amount / 100,
          },
        ];
      });
      return parsedItems.sort((a, b) => a.limit - b.limit);
    },

    cancel() {
      this.$store.dispatch('showLeaveGuard', {
        message: 'Are you sure you want to cancel your Balloon subscription?',
        okText: 'Cancel Subscription',
        cancelText: 'Keep Subscription Active',
        okHandler: async () => {
          await this.$store.dispatch('cancelTeamSubscription', this.team.id);
          await this.$store.dispatch('setTeamSubscription', this.team.id);
        },
      });
    },
    restart() {
      this.$store.dispatch('showLeaveGuard', {
        message: 'Are you sure you want to restart your Balloon subscription?',
        okText: 'Restart Subscription',
        cancelText: 'Cancel',
        okHandler: async () => {
          await this.$store.dispatch('restartTeamSubscription', this.team.id);
          await this.$store.dispatch('setTeamSubscription', this.team.id);
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.plan {
  min-height: 163px;
  width: 337px;
 }

.stripe {
  min-height: 220px;
  p { font-weight: 500; }

  .plan { width: 250px; }
}

.flight-creators {
  border-right: 1px solid @tan;
  min-width: 230px;

  .seats { max-width: 100px; }
}
</style>
