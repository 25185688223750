<template>
  <section class="mt-8 h-75">
    <div class="row">
      <div class="col-8 offset-2 d-flex flex-column">
        <h1>Your Team & Contributors</h1>
        <TeamMemebersListHeader v-model="filter"
          :search-count="filteredUsers.length"
          :total-count="usersCount"/>
      </div>
    </div>

    <div class="row h-100 flex-grow-1 py-5">
      <div class="col-8 h-100 offset-2">
        <div
          class="container-fluid h-100 table-wrapper"
          v-bar="{ scrollThrottle: 30 }">
          <div>
            <LoadingBalloonAnimation width="200" height="125" v-if="isLoading"/>

            <b-table
              v-else
              ref="table"
              sort-by="teamRole"
              :sort-desc=true
              :fields="tableFields"
              :items="filteredUsers"
              :tbody-tr-class="rowClass"
              :busy="isLoading"
            >
              <template v-slot:cell(name)="data">
                {{data.item.firstName}} {{data.item.lastName}}
              </template>
              <template v-slot:cell(actions)="data">
                <b-dropdown
                  data-tour="user-actions"
                  no-caret right
                  menu-class="actions-dropdown"
                  :disabled="!isActiveSubscription"
                >
                  <template slot="button-content">
                    <icon
                      name="more_actions"
                      aria-label="More Action"
                      class="d-block hover-scale py-2 pl-4 pr-3"
                    />
                  </template>
                  <b-dropdown-item-button v-if="data.item.teamStatus !== 'deactivated' &&
                    data.item.teamStatus === 'pending'"
                    @click="resendInvite(data.item.email)">
                      <icon name="message" stroke />
                      <span>Resend Invitation to Team</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button v-if="data.item.teamStatus !== 'deactivated' &&
                    data.item.teamRole !== 'team-administrator'"
                    @click="makeAdmin(data.item.id)">
                      <icon name="make_admin" stroke />
                      <span>Make Admin</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button v-if="data.item.teamStatus !== 'deactivated' &&
                    data.item.teamRole !== 'creator'"
                    @click="makeCreator(data.item.id)">
                      <icon name="make_creator" stroke />
                      <span>Make Flight Creator</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button v-if="data.item.teamStatus !== 'deactivated' &&
                    data.item.teamRole !== 'contributor'"
                    @click="makeContributor(data.item.id)">
                      <icon name="make_guest" stroke />
                      <span>Make Contributor</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button v-if="data.item.teamStatus !== 'deactivated'"
                    @click="deactivate(data.item.id)">
                      <icon name="deactivate" stroke />
                      <span>Deactivate User</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button v-if="data.item.teamStatus === 'deactivated'"
                    @click="reactivate(data.item.id)">
                      <icon name="activate" stroke />
                      <span>Re-activate User</span>
                  </b-dropdown-item-button>
                </b-dropdown>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <LeaveGuard />
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TeamMemebersListHeader from '@/components/Team/TeamMembersListHeader.vue';
import subscriptionService from '@/services/subscription';
import LeaveGuard from '@/components/LeaveGuard.vue';
import { formatPreviewMessage } from '../../utils/subscriptionPlans';

export default {
  name: 'TeamMembersList',

  components: { TeamMemebersListHeader, LeaveGuard },

  data() {
    return {
      filter: {
        currentTab: 'team-administrator',
        searchString: '',
      },
      roles: [
        { label: 'Administrator', value: 'team-administrator' },
        { label: 'Flight Creator', value: 'creator' },
        { label: 'Contributor', value: 'contributor' },
      ],
      statuses: [
        { label: 'Active', value: 'active' },
        { label: 'Pending', value: 'pending' },
        { label: 'Deactivated', value: 'deactivated' },
      ],
    };
  },

  methods: {
    async previewUpdates(selected, applyChange) {
      try {
        let preview = formatPreviewMessage({ totalCount: 0 });
        if (this.billingMethod === 'stripe') {
          const data = await subscriptionService.previewSubscriptionUpdate(
            this.activeTeamId,
            selected,
          );
          preview = formatPreviewMessage(data);
        }
        this.$store.dispatch('showLeaveGuard', {
          message: `${preview}`,
          cancelText: 'Cancel changes',
          okText: 'Accept changes',
          okHandler: async () => {
            await this.$store.dispatch(applyChange.funcName, {
              userId: applyChange.userId,
              planId: applyChange.planId,
              participantLimit: applyChange.participantLimit,
            });
            this.$store.dispatch('successToast', applyChange.successMsg);
          },
        });
      } catch (e) {
        this.$store.dispatch('logErrorToFS', e.message);
        this.$store.dispatch('errorToast', 'There was an error updating the user');
      }
    },
    async resendInvite(email) {
      await this.$store.dispatch('resendInvite', email);
      this.$store.dispatch('infoToast', 'Another email invitation has been sent.');
    },
    async makeAdmin(userId) {
      const planRequested = {};
      const updateData = {
        funcName: 'makeUserAdmin',
        successMsg: 'User role has been changed to Admin.',
        userId,
      };
      if (this.userIsContributor(userId)) {
        updateData.planId = this.planId;
        updateData.participantLimit = this.participantLimit;
        planRequested[this.planId] = 1;
        if (this.userIsTokenAuthenicated(userId)) {
          updateData.successMsg += ' They will receive email to create account.';
        }
      }
      await this.previewUpdates(planRequested, updateData);
    },
    async makeCreator(userId) {
      this.shouldPreventRoleDowngrade(userId);
      const planRequested = {};
      const updateData = {
        funcName: 'makeUserCreator',
        successMsg: 'User role has been changed to Flight Creator.',
        userId,
      };
      if (this.userIsContributor(userId)) {
        updateData.planId = this.planId;
        updateData.participantLimit = this.participantLimit;
        planRequested[this.planId] = 1;
        if (this.userIsTokenAuthenicated(userId)) {
          updateData.successMsg += ' They will receive email to create account.';
        }
      }
      await this.previewUpdates(planRequested, updateData);
    },
    makeContributor(userId) {
      this.shouldPreventRoleDowngrade(userId);
      this.$store.dispatch('makeUserContributor', { userId }).then(() => {
        this.$store.dispatch('successToast', 'User is now a Contributor');
        if (userId === this.loggedInUser.id) {
          setTimeout(() => window.location.reload(), 1000);
        }
      });
    },
    async deactivate(userId) {
      this.shouldPreventRoleDowngrade(userId);
      await this.$store.dispatch('deactivateUser', { userId });
      this.$store.dispatch('successToast', 'User has been deactivated');
    },
    async reactivate(userId) {
      const planRequested = {};
      const updateData = {
        funcName: 'reactivateUser',
        successMsg: 'User has been re-activated',
        userId,
      };
      if (!this.userIsContributor(userId)) {
        updateData.planId = this.planId;
        updateData.participantLimit = this.participantLimit;
        planRequested[this.planId] = 1;
      }
      await this.previewUpdates(planRequested, updateData);
    },
    roleFormatter(value) {
      try {
        return this.roles.find((role) => role.value === value).label;
      } catch (e) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      }
    },
    statusFormatter(value) {
      try {
        return this.statuses.find((status) => status.value === value).label;
      } catch (e) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      }
    },
    rowClass(item) {
      if (item && item.teamStatus === 'deactivated') {
        return 'deactivated';
      }
      return 'active';
    },
    userIsContributor(userId) {
      return this.users.find((user) => user.id === userId && user.teamRole === 'contributor');
    },
    shouldPreventRoleDowngrade(userId) {
      const oneAdminOnActiveTeam = this.users.filter((user) => user.teamRole === 'team-administrator' && user.teamStatus === 'active').length <= 1;
      const userIsAdmin = this.users.find((user) => user.id === userId && user.teamRole === 'team-administrator');
      if (oneAdminOnActiveTeam && userIsAdmin) {
        this.$store.dispatch('errorToast', 'The team needs at least one administrator');
        throw new Error('The team needs at least one administrator');
      }
    },
    userIsTokenAuthenicated(userId) {
      return this.users.find((user) => user.id === userId && user.teamAuthentication === 'token');
    },
  },

  computed: {
    ...mapState({
      activeTeamId: (state) => state.teams.activeTeamId,
    }),

    ...mapGetters({
      loggedInUser: 'loggedInUser',
      users: 'allUsers',
      isActiveSubscription: 'isActiveSubscription',
      participantLimit: 'participantLimitOnActiveTeam',
      planId: 'planIdOnActiveTeam',
      billingMethod: 'billingMethod',
    }),

    isLoading() {
      return this.users === 0;
    },

    contributors() {
      return this.users.filter((user) => user.teamRole === 'contributor');
    },

    teamAdminAndFlightCreators() {
      return this.users.filter((user) => user.teamRole !== 'contributor');
    },

    usersCount() {
      return {
        creators: this.teamAdminAndFlightCreators.length,
        contributors: this.contributors.length,
      };
    },

    filteredUsers() {
      let filtered = this.teamAdminAndFlightCreators;

      // first filter by role
      if (this.filter.currentTab === 'contributors') {
        filtered = this.contributors;
      }

      // further filter if search is in use
      if (this.filter.searchString.length > 0) {
        const query = this.filter.searchString.toLowerCase();
        return filtered.filter((user) => [user.firstName, user.lastName, user.email]
          .some((value) => value.toLowerCase().includes(query)));
      }
      return filtered;
    },

    tableFields() {
      const tableFields = [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'teamRole',
          label: 'Role',
          formatter: 'roleFormatter',
        },
        {
          key: 'flightParticipantLimit',
          label: 'Limit',
        },
        {
          key: 'teamStatus',
          label: 'Status',
          formatter: 'statusFormatter',
        },
        {
          key: 'actions',
          label: 'Actions',
          class: 'text-right',
        },
      ];

      if (this.filter.currentTab === 'contributors') {
        tableFields.splice(2, 2);
      }
      return tableFields;
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .dropdown-toggle {
  width: auto;
}
</style>
