<template>
  <div class="container-fluid h-100 d-flex flex-column">
    <LoadingBalloonAnimation v-if="isLoading" />

    <template v-else>
      <TeamSubscription
        :teamId="activeTeamId"
        :type="billingMethod"
      />
      <TeamMembersList />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import TeamSubscription from '@/components/Team/TeamSubscription.vue';
import TeamMembersList from '@/components/Team/TeamMembersList.vue';

export default {
  name: 'TeamView',
  components: { TeamSubscription, TeamMembersList },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      teams: 'allTeams',
      billingMethod: 'billingMethod',
    }),

    ...mapState({
      activeTeamId: (state) => state.teams.activeTeamId,
    }),
  },

  created() {
    this.fetchTeamData();
  },

  methods: {
    async fetchTeamData() {
      this.isLoading = true;
      try {
        if (this.billingMethod === 'stripe') {
          await Promise.all([this.$store.dispatch('setUsersOnTeam'), this.$store.dispatch('setTeamSubscription', this.activeTeamId)]);
        } else {
          await this.$store.dispatch('setUsersOnTeam');
        }
      } catch (e) {
        this.$store.dispatch('errorToast', e.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
